import React from 'react'
import LoginForm from '../components/screens/LoginForm/LoginForm'



const Login = () => {
    return (
        <div className='h-full w-full flex justify-center'>
            <LoginForm />
        </div>

    )
}

export default Login